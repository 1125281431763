window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

window.addEventListener('heyflow-screen-view', (event) => {
    // Hide button when entering the progress bar screen
    if (event.detail.screenID === 'id-b4c03f35') {
        const targetButton = document.getElementById('button-1fdfe248');
        if (targetButton) {
            targetButton.style.opacity = '0';
            targetButton.style.visibility = 'hidden'; // Using visibility instead of display to maintain layout
        }
    }
});

document.addEventListener('DOMContentLoaded', function() {
    function getMonthName(date) {
      return date.toLocaleString('default', { month: 'long' });
    }

    function updateLabels() {
      const now = new Date();
      const futureDate = new Date();
      futureDate.setMonth(now.getMonth() + 2);
      
      document.getElementById('start-month').textContent = getMonthName(now);
      document.getElementById('end-month').textContent = getMonthName(futureDate);
    }

    updateLabels();
});

class ProgressBar {
    constructor(id, finalTitle) {
        this.id = id;
        this.finalTitle = finalTitle;
        this.percentage = 0;
        this.intervalId = null;
        this.element = document.getElementById(id + '-progress');
        this.isComplete = false;
    }

    updateProgressBar() {
        const progressPath = this.element.querySelector('.progress-bar .progress');
        const dashOffset = 100 - this.percentage;
        progressPath.style.strokeDashoffset = dashOffset;
        
        this.element.querySelector('.progress-percentage').textContent = `${this.percentage}%`;

        if (this.percentage >= 100 && !this.isComplete) {
            this.completeProgress();
        }
    }

    completeProgress() {
        this.isComplete = true;
        this.element.querySelector('.progress-title').textContent = this.finalTitle;
        this.element.querySelector('.progress-percentage').style.display = 'none';
        this.element.querySelector('.check-icon').style.display = 'block';
        clearInterval(this.intervalId);
        
        const event = new Event('progressComplete');
        this.element.dispatchEvent(event);
        
         if (this.id === 'third') {
            const targetButton = document.getElementById('button-1fdfe248');
            if (targetButton) {
                targetButton.style.visibility = 'visible';
                targetButton.style.transition = 'opacity 0.5s ease-in-out';
                // Small delay to ensure the transition takes effect
                setTimeout(() => {
                    targetButton.style.opacity = '1';
                }, 10);
            }
        }
    
    }

    showPopup() {
        const popupElement = document.getElementById(this.id + 'Popup');
        const overlayElement = document.getElementById(this.id + 'PopupOverlay');
        
        popupElement.style.display = 'block';
        overlayElement.style.display = 'block';
        
        // Trigger reflow to ensure transition works
        popupElement.offsetHeight;
        
        popupElement.classList.add('visible');
        overlayElement.classList.add('visible');
    }

    hidePopup() {
        const popupElement = document.getElementById(this.id + 'Popup');
        const overlayElement = document.getElementById(this.id + 'PopupOverlay');
        
        popupElement.classList.remove('visible');
        overlayElement.classList.remove('visible');
        
        // Wait for transition to complete before hiding
        setTimeout(() => {
            popupElement.style.display = 'none';
            overlayElement.style.display = 'none';
        }, 300);
    }

    activate() {
        this.element.classList.add('visible');
    }

    startProgress() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        
        this.intervalId = setInterval(() => {
            if (this.percentage >= 100) {
                clearInterval(this.intervalId);
                this.completeProgress();
                return;
            }
            
            if (this.percentage === 49) {
                clearInterval(this.intervalId);
                this.showPopup();
                return;
            }
            
            this.percentage++;
            this.updateProgressBar();
        }, 75);
    }

    continueProgress() {
        this.hidePopup();
        this.percentage++;
        this.startProgress();
    }
}

// Initialize progress bars when DOM is ready
document.addEventListener('DOMContentLoaded', () => {
    window.firstProgress = new ProgressBar('first', 'Goals');
    window.secondProgress = new ProgressBar('second', 'Custom Fit');
    window.thirdProgress = new ProgressBar('third', 'Selected Essentials');
});

// Listen for the heyflow screen view event
window.addEventListener('heyflow-screen-view', (event) => {
    if (event.detail.screenID === 'id-b4c03f35') {
        // Start first progress bar when entering the specified screen
        setTimeout(() => {
            firstProgress.activate();
            firstProgress.startProgress();
        }, 100);
    }
});

// Define continueProgress function in global scope
window.continueProgress = function(which) {
    if (which === 'first') {
        firstProgress.continueProgress();
        firstProgress.element.addEventListener('progressComplete', () => {
            setTimeout(() => {
                secondProgress.activate();
                secondProgress.startProgress();
            }, 1000);
        }, { once: true });
    } else if (which === 'second') {
        secondProgress.continueProgress();
        secondProgress.element.addEventListener('progressComplete', () => {
            setTimeout(() => {
                thirdProgress.activate();
                thirdProgress.startProgress();
            }, 1000);
        }, { once: true });
    } else {
        thirdProgress.continueProgress();
    }
}
